import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_API_BASE_URL,
});

// OIDCAxiosHandler clears interceptors as soon as it receives a valid token
// so we abort all request which have been sent before that
axiosInstance.interceptors.request.use((config) => {
  const controller = new AbortController();
  controller.abort();

  return {
    ...config,
    signal: controller.signal,
  };
});

export const rawAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_API_BASE_URL,
});

export default axiosInstance;
