import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import './sentry-styles.css';

import App from './App';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.VITE_SENTRY_ENABLED || false,
  environment: import.meta.env.VITE_ENVIRONMENT || 'local',
  release: import.meta.env.VITE_RELEASE || 'local',
  integrations: [
    new Sentry.BrowserTracing({
      // TODO: Adjust CORS to allow Baggage header
      tracePropagationTargets: ['localhost' /*import.meta.env.VITE_BACKEND_API_BASE_URL*/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      mask: ["input[type='password']"],
      blockAllMedia: false,
    }),
  ],
  // store errors received offline here
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  // Performance Monitoring
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE + '') || 0.1,
  // Session Replay
  replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE + '') || 0.1, // The sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate:
    parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE + '') || 1.0, // The sample rate at 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
