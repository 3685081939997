import {
  ApiFetcherArgs,
  AppRouter,
  initClient,
  InitClientArgs,
  InitClientReturn,
} from '@ts-rest/core';
import { useMemo } from 'react';
import { AxiosError, isAxiosError, Method } from 'axios';
import * as Sentry from '@sentry/react';

import axiosInstance from 'infrastructure/api';

const axiosApi = async ({ path, body, headers, method, signal }: ApiFetcherArgs) => {
  try {
    const result = await axiosInstance.request({
      method: method as Method,
      url: path,
      headers,
      data: body,
      signal,
    });

    return {
      status: result.status,
      body: result.data,
      headers: new Headers(result.headers as HeadersInit),
    };
  } catch (e: Error | AxiosError | unknown) {
    if (isAxiosError(e)) {
      const error = e as AxiosError;

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const response = error.response;
        if (response.status === 403) {
          // user experienced a permission error
          const serverError = response.data as { message?: string } | null;
          Sentry.captureEvent({
            level: 'warning',
            message: serverError?.message
              ? (serverError.message as string)
              : 'Unknown forbidden error',
            tags: {
              apiPath: path,
              method,
            },
          });
        }
        if (response.status > 500) {
          // probably a server outage, Internal Server Errors are captured within API itself
          Sentry.captureEvent({
            level: 'error',
            message: `API Unreachable with status: ${response.status}`,
            tags: {
              apiPath: path,
              method,
            },
          });
        }
        return {
          status: response.status,
          body: response.data,
          headers: response.headers as unknown as Headers,
        };
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        throw error.request;
      }
    }

    throw e;
  }
};
export default function useTsRestClient<T extends AppRouter, TClientArgs extends InitClientArgs>(
  router: T,
): InitClientReturn<T, TClientArgs> {
  // @ts-expect-error initClient somehow resolves to never here, bug?
  return useMemo<InitClientReturn<T, TClientArgs>>(() => {
    return initClient(router, {
      baseUrl: import.meta.env.VITE_BACKEND_API_BASE_URL as string,
      baseHeaders: {
        'Content-Type': 'application/json',
      },
      api: axiosApi,
    });
  }, [router]);
}
