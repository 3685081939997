import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorHttpStatusCode } from '@ts-rest/core';
import { CanceledError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { FC, PropsWithChildren } from 'react';

export interface TsRestErrorResponse<ErrorBody extends { message: string } = { message: string }> {
  status: ErrorHttpStatusCode;
  body: ErrorBody;
}

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: Error | TsRestErrorResponse;
  }
}

const globalForbiddenErrorHandler = (error: Error | TsRestErrorResponse) => {
  if ('status' in error && [401, 403, 404].includes(error.status)) {
    if (error.status === 403) {
      const forbiddenMessage =
        typeof error.body === 'object' && error.body && 'message' in error.body
          ? error.body.message
          : 'Missing unknown permission/role';
      enqueueSnackbar(`Missing Permission | ${forbiddenMessage}`, {
        variant: 'error',
        preventDuplicate: true,
      });
      Sentry.captureEvent({
        level: 'warning',
        message: forbiddenMessage,
        tags: {},
      });
    }

    // Do nothing for 401 & 404
    return;
  }

  if ('status' in error && [401, 403, 404, 409].includes(error.status)) {
    // do not report error to Sentry
    return;
  }

  // do not report cancelled requests
  if (error instanceof CanceledError) {
    return;
  }
  // capture only real Errors, not these received from the server
  if (error instanceof Error) {
    Sentry.captureException(error);
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.silentError) {
        return;
      }

      return globalForbiddenErrorHandler(error);
    },
  }),
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 15, // 15 mins
      networkMode: 'offlineFirst',
      // we hand over this to the useOfflineModeTokenRefresh in order to wait for token refresh
      refetchOnReconnect: false,
      refetchOnWindowFocus: !import.meta.env.DEV,
      retry: (failureCount, error) => {
        if ('status' in error && [400, 401, 403, 404].includes(error.status)) {
          return false;
        }
        return failureCount < 3;
      },
    },
    mutations: {
      onError: globalForbiddenErrorHandler,
      networkMode: 'offlineFirst',
    },
  },
});

const InfrastructureProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}

      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
    </QueryClientProvider>
  );
};

export default InfrastructureProvider;
